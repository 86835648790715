<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">当前积分：{{ integral }}</div>  
        <div class="rb1" @click="goback">
          <img src="@/assets/fh.png" class="icon" />
          <div class="text">返回</div>
        </div>
      </div>
      <div class="content">
        <div class="items">
          <div class="item">
            <div class="box z3">
              <div class="name">
                <span class="headline h0 va2">选择房间</span>
              </div>                    
            </div>  
            <div class="box z4">
              <div class="name">               
                <select class="form_select" v-model="selectItem" >
                    <option v-for="ritem in roomlist" :key="ritem.id" :value="ritem.id">{{ritem.name}}</option>
                </select>
              </div>                   
            </div>          
          </div>
          <div class="item" v-for="nitem in nowitems" :key="nitem.id">
            <div class="box yy2" @click="onAdjust(nitem,'0')">
              <div class="name dx">
                <img src="@/assets/js.png" class="dxicon" />
              </div>             
            </div>
            <div class="box z1">
              <div class="name tt">
                <span class="roomcode f">{{ nitem.user_name }}</span>
              </div>
              <div class="name">
                <span class="region">{{ nitem.mobile }}</span>
              </div>
              <div class="name">
                <span class="region">{{ nitem.certificate_type }}</span>
              </div>
            </div>
            <div class="box z2">
              <div class="name">                              
                <div v-if="nitem.checkin_type === '0'" class="rb2">
                  <div class="text">扫</div>
                </div>
                <div v-if="nitem.checkin_type === '1'" class="rb2 xie">
                  <div class="text">协</div>
                </div>
              </div>              
            </div>
            <div class="box z3">
              <div class="name">                              
                <img :src="nitem.picture" class="imgpic" />
              </div>              
            </div>           
          </div> 
          <div class="item">
            <div class="box qq">
              <div class="name">
                <span class="headline h0">请调整入住人</span>
              </div>                     
            </div>           
          </div>
          <div class="empty" v-if="items.length === 0">
            <div>
              <img src="@/assets/empty.png" />
            </div>
            <div class="text">当前无信息</div>
          </div>
          <div class="item" v-for="item in items" :key="item.id">
            <div class="box yy2" @click="onAdjust(item,'1')">
              <div class="name dx">
                <img src="@/assets/zj.png" class="dxicon" />
              </div>             
            </div>
            <div class="box z1">
              <div class="name tt">
                <span class="roomcode f">{{ item.user_name }}</span>
              </div>
              <div class="name">
                <span class="region">{{ item.mobile }}</span>
              </div>
              <div class="name">
                <span class="region">{{ item.certificate_type }}</span>
              </div>
            </div>
            <div class="box z2">
              <div class="name">                              
                <div v-if="item.state === '0'" class="rb2">
                  <div class="text">扫</div>
                </div>
                <div v-if="item.state === '1'" class="rb2 xie">
                  <div class="text">协</div>
                </div>
              </div>              
            </div>
            <div class="box z3">
              <div class="name">                              
                <img :src="item.picture" class="imgpic" />
              </div>              
            </div>          
          </div>
        </div>
      </div>
      <!--<div class="list_ll list2">
        <div class="ll">
          <div class="button primary mt" @click="next">下一步</div>         
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import qs from 'qs';
export default {
  created() {
    
    //this.roomlist = this.$route.query.roomlist;
    this.type = this.$route.query.type;
    this.unionid = this.$route.query.unionid;
    this.srole = this.$route.query.srole;

    this.selectItem = this.roomlist[0].id;
    //this.query();

  },
  data() {
    return {
      integral: 0,
      type: '',
      unionid: '',
      srole: true,
      selectItem: '',
      roomname: '503',
      region: 'C区',
      floor: '5',
      enterdate: '2020-01-02',
      leavedate: '2020-01-05',
      days: '3',   
      ClientID:'', 
      SiteCode:'',
      IdNum:'',
      hrefurl:'',
      listidnum:'',
      roomlist: [
        {id:'101', name:'101'},
        {id:'103', name:'103'}
      ],
      nowitems: [
        {user_name: '王先生', mobile: '136****5612', certificate_type: '2203**********5612', checkin_type: '0', picture: '/img/nan.png'},
        {user_name: '罗女士', mobile: '131****8633', certificate_type: '2203**********5612', checkin_type: '1', picture: '/img/nv.png'}
      ],
      items: [
        {dxflag: false, user_name: '王先生', mobile: '136****5612', certificate_type: '2203**********5612', state: '0', picture: '/img/nan.png'},
        {dxflag: false, user_name: '罗女士', mobile: '131****8633', certificate_type: '2203**********5612', state: '1', picture: '/img/nv.png'},
        {dxflag: false, user_name: '李先生', mobile: '133****8876', certificate_type: '2203**********5612', state: '1', picture: '/img/nan.png'}
      ]
    };
  },
  methods: {
    goback() {
      this.$router.replace({path:'/barcounter/adjust_room_state', query:{
        type:this.type,
        unionid:this.unionid,
        srole:this.srole
      }});
    },
    async onAdjust(item,flag) {
      alert(item.user_name + '-' + flag);
      /*if(item.state == '0'){
        alert("扫码入住的不能取消!");
        return;
      }
      //alert(item.certificate_code);
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_usertype',
        qs.stringify({
          idnum: item.certificate_code
        })
      );
      if (data.flag === '0') {
        item.dxflag=!item.dxflag; 
      }else{
        Dialog({ message: "此人已经入住！" });
      }*/
    },
    async query() {
      let { data } = await this.axios.get('/ctid/TldjService/dj_selectpeers');
      //this.items = data.data;
      var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
      this.items = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;
      
    }  
  }
};
</script>

<style lang="less" scoped>

.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 50px;
    color: #fff;
    font-size: 33px;
    float: left;
    margin-top: 10px;
  }
  
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    //width: 236px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  
}

.rb2 {
    height: 80px;
    width: 80px;
    overflow: hidden;
    float: right;
    .icon1 {
      width: 50px;
      height: 50px;
      margin: 8px;
      display: block;

    }
  }
.content {
  padding: 0px;
  margin-top: 40px;
}

.xg {
  width: 660px;
  overflow: hidden;
  margin: 0px auto;
  padding: 30px 0px 20px 0px;
}

.items {
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 32px;
  min-height: 616px;
  .item {
    margin: 0px 26px;
    padding: 24px 14px 22px;
    overflow: hidden;
    display: flex;
    border-bottom: 2px solid #eee;
  }
  .box {
    flex: 1;
    overflow: hidden;
    flex:0 0 auto;
    &.zz {
      width: 60%;
    }
    &.yy {
      width: 15%;
    }
    &.yy1 {
      width: 25%;
    }
    &.z1 {
      width: 45%;
    }
    &.z2 {
      width: 10%;
    }
    &.z3 {
      width: 30%;
    }
    &.z4 {
      width: 70%;
    }
    &.yy2 {
      width: 15%;     
    }
    &.yy3 {
      width: 36%;
    }
    &.qq {
      width: 100%;
    }
    .name {
      line-height: 50px;
      overflow: hidden;
      font-size: 32px;
      &.tt {
        margin-top: 23px;
      }
      &.dx {
        margin-top: 70px;
      }
      .zdy {
        float: left;
      }
      .headline {
        font-size: 26px;
        color: rgb(168, 169, 170);     
        &.h1 {
          margin-right: 30px;
        }
        &.h2 {
          float: right;
        }
        &.h0 {
          font-size: 28px;
          color: rgb(0, 0, 0);           
        }  
        &.h3 {
          margin-right: 35px;
        }
        &.va {
          margin-top: 5px;
        }
        &.va1 {
          height: 60px; 
          line-height: 60px;
          margin-top: 5px;
        }
        &.va2 {
          height: 60px; 
          line-height: 60px;
        }
        &.co {
          color: rgb(17, 34, 131); 
        }
      }
      .imgpic {
        display: block;
        //width: 310px;  
        width: 160px;  
        height: 196px; 
        float: right;
      }
      .datespace {
        width: 100%; 
        height: 60px; 
        line-height: 60px;
        padding-top: 2.5px;
        color: rgb(168, 169, 170);
        font-size: 26px;
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background-color: transparent;
        cursor: pointer;  
        &.bt {
          width: 110px; 
        }          
      }
      .dxicon {
        width: 39px;
        height: 39px;
        vertical-align: middle;
      }
      .state {
        display: block;
        font-size: 26px;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
        &.xq {
          color: rgb(33, 18, 243);
        }
      }
      .floor {
        display: block;
        float: right;
        font-size: 26px;
        color: rgb(168, 169, 170);
        margin-right: 20px;
      }
      .roomcode {
        margin-right: 80px;
        &.f {
          font-size: 30px;
          color: rgb(168, 169, 170);
        }
        &.r {
          margin-right: 100px;
        }
      }
      .region {
        font-size: 26px;
        color: rgb(168, 169, 170);
      }
      .cx {
        height: 76px;
        vertical-align: middle;
      }
      .icon {
        width: 48px;
        height: 48px;
        vertical-align: middle;
      }
      .input {
        flex: 1;
        margin-right: 15px;
        margin-top: 10px;
        vertical-align: middle;
        line-height: 76px;
        float: left;
        width: 160px;
        border: 0;
        color: #333;
        &.qy {
          font-size: 30px;
          color: rgb(184, 188, 190);
        }
      }
      ::-webkit-input-placeholder { /* WebKit browsers */
        color:rgb(184, 188, 190);
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:rgb(184, 188, 190);
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:rgb(184, 188, 190);
      }
      :-ms-input-placeholder { /* Internet Explorer 10+ */
        color:rgb(184, 188, 190);
      }
      .rb2 {
        height: 50px;
        width: 50px;
        float: right;
        overflow: hidden;
        background: rgb(130, 189, 217);
        margin-top: 70px;
        border-radius: 15px;
        &.xie {
          background: rgb(170, 149, 207);
        }
        .icon {
          width: 30px;
          height: 30px;
          margin: 14px 5px 14px 13px;
          display: block;
          float: left;
        }        
        .text {
          float: left;
          color: #fff;
          font-size: 30px;
          line-height: 50px;
          display: block;
          margin-left: 9px;
        }
      }
      .form_select {
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        /* 右侧添加小箭头的背景图 */
        background: url('/img/arrow_1.png') 98% center no-repeat;
        background-size: 26px;
        width: 100%;
        height: 60px;
        background-color: transparent;
        font-size: 25px;
        color: rgb(136, 134, 134);
        line-height: 60px;
        vertical-align: middle;
        //direction: rtl;//靠右
        padding-right: 40px;
      } 
      select:focus { outline: none; }
      
    }
    .name1 {
      line-height: 50px;
      overflow: hidden;
      font-size: 26px;
      .state {
        display: block;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
      }
      .floor {
        display: block;
        float: right;
        color: rgb(184, 188, 190);
        margin-right: 20px;
      }
    }
    .unames {
      line-height: 40px;
      font-size: 28px;
      margin-top: 8px;
      color: #918e8e;
      .pz {
        width: 46px;
        height: 46px;
      }
    }
    .del {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      //margin-top: 18px;
    }
    .edit {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      margin-right: 20px;
    }
    .view {
      margin-top: 18px;
      width: 198px;
      height: 64px;
      border: 2px solid rgba(0, 29, 255, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 64px;
      color: rgba(0, 29, 255, 1);
      font-size: 28px;
      float: right;
      margin-right: 30px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
.list_ll {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  &.list2 {
    margin-top: 15px;
  }
  .ll {
    width: 90%;
    margin: 0px auto;
  }
  
}
</style>
